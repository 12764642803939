<template>
  <div>
    <v-row>
      <v-col></v-col>
      <v-col>
        <v-card class="elevation-0">
          <div style="padding: 10px" class="text-center">
            <v-btn @click="backupDatabase" color="accent" class="elevation-0">
              Backup Database
            </v-btn>
            <br />
            <br />
            <v-divider></v-divider>
            <h3 style="padding-top: 10px; padding-bottom: 10px">Upload a Backup</h3>
            <v-row>
              <v-col cols="8"
                ><v-file-input
                  v-model="file"
                  outlined
                  accept=".sql"
                  label="File input"
                  dense
                  class="shrink"
                ></v-file-input></v-col
              ><v-col cols="1"
                ><v-btn class="elevation-0" @click="upload_database" color="primary"
                  >Upload</v-btn
                ></v-col
              >
            </v-row>
            <br />
            <v-divider> </v-divider>
            <br />
            <v-dialog v-model="dialog" max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="accent" class="elevation-0">
                  Empty Database
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5"> empty Database </v-card-title>
                <v-card-text
                  >Are you sure want to empty the Database? this process cannot be
                  undone.</v-card-text
                >
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn color="green darken-1" text @click="dialog = false">
                    Cancel
                  </v-btn>
                  <v-btn color="green darken-1" text @click="emptyDatabase">
                    Proceed
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-card></v-col
      >
      <v-col></v-col>
    </v-row>
  </div>
</template>
<script>
import {
  backupDatabase,
  emptyDatabase,
  uploadDatabase,
  getBackupDatabaseURL,
} from "@/ApiManager";

export default {
  computed: {},

  data() {
    return {
      file: null,
      dialog: false,
    };
  },
  components: {},
  methods: {
    async emptyDatabase() {
      this.$store.state.loading = true;
      this.dialog = false;
      await this.backupDatabase();
      await emptyDatabase(this.file);
      this.$store.state.loading = false;
    },
    async upload_database() {
      if (this.file != null) {
        this.$store.state.loading = true;
        await uploadDatabase(this.file);
        this.$store.state.loading = false;
      }
    },
    async backupDatabase() {
      var url = await getBackupDatabaseURL();
      window.location.assign(url, "_blank");
    },
  },
  created() {},
};
</script>

<style>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: var(--v-primaryLighter-base);
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 20px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 16 px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}
</style>
